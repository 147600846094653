import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import "bulma/css/bulma.css"

const Header = ({ siteTitle }) => (
  <header
    style={{
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      margin: `0 auto 10rem`,
      maxWidth: 1220,
      padding: `1.45rem 1.0875rem`,
    }}
  >
    <h3 style={{ margin: 0 }}>
      <Link
        to="/"
        style={{
          color: `black`,
          textDecoration: `none`,
        }}
      >
        {siteTitle}
      </Link>
    </h3>
    <button className="button">Subscribe</button>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
