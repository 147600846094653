import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"

const Footer = ({ siteTitle }) => {
  const [email, setEmail] = useState("")
  const [successMessage, setSuccessMessage] = useState()
  const handleSubmit = evt => {
    evt.preventDefault()
    // TODO: Put method in a service
    fetch("https://api.convertkit.com/v3/forms/1185905/subscribe", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        api_key: "7xO8167LJdwx_mshq5mMWg",
        email: email,
      }),
    })
      .then(res => setSuccessMessage(`You've successfully signed up!`))
      .catch(error => {
        console.error("Error:", error)
      })
  }

  return (
    <footer
      style={{
        background: "#f2f3f4",
        color: "#444",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: 300,
        marginTop: 200,
      }}
    >
      <div style={{ width: 1220, margin: "0 auto", padding: 20 }}>
        <h2>Join the Newsletter</h2>
        <p>A fresh set of math discussions for use in the classroom.</p>
        {successMessage ? (
          <p>{successMessage}</p>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="field">
              <label>
                <div className="control">
                  <input
                    style={{ width: 250 }}
                    className="input"
                    type="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    placeholder="joe@montana.com"
                  />
                </div>
              </label>
            </div>

            <div className="field is-grouped">
              <div className="control">
                <button type="submit" className="button" value="Subscribe">
                  Subscribe
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    </footer>
  )
}

export default Footer
