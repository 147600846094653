import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import SEO from "./seo"

const Intro = ({ data }) => (
  <div style={{ marginBottom: 50 }}>
    <SEO title={data.title} />
    <h1>{data.title}</h1>
    <br />
    <p>{data.source}</p>
    <br />
    <br />
    <br />
    <p>{data.description}</p>
    <br />
    {data.questions.length > 0 && (
      <div className="card">
        <div className="card-content">
          {data.questions.map((item, i) => (
            <textarea
              key={i}
              style={{ marginBottom: 20 }}
              className="input"
              type="text"
              placeholder={`${i + 1}. ${item}`}
            />
          ))}
        </div>
      </div>
    )}
  </div>
)

Intro.propTypes = {
  data: PropTypes.object,
}

Intro.defaultProps = {
  data: {},
}

export default Intro
